<template>
  <div>
    <el-button v-if="!detailMode" icon="el-icon-plus" @click="onAddRelated">新增相关单位</el-button>
    <div class="list-wrapper" :style="{background: detailMode? '#fff':'#f3f6fe'}">
      <!-- 新增编辑 -->
      <div v-if="!detailMode">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :lg="8" v-for="item in relatedList" :key="item.code">
            <div class="list-item" style="box-shadow: 0px 4px 16px rgba(48, 49, 51, 0.1);">
              <i class="el-icon-error" v-if="item.code !== '0'" @click="onDelete(item.code)"></i>
              <el-form ref="form" :model="item" :rules="rules" label-width="80px">
                <el-form-item prop="relatedPartyId" :label="item.code ==='0'?'建设单位': '相关单位'">
                  <el-select filterable placeholder="请选择相关单位（可搜索）" v-model="item.relatedPartyId">
                    <el-option
                      v-for="item in buildOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item prop="contactAddress" label="联系地址">
                  <el-input
                    placeholder="请输入联系地址"
                    v-model.trim="item.contactAddress"
                    maxlength="100"
                  ></el-input>
                </el-form-item>

                <el-form-item prop="contacts" label="联系人">
                  <el-input placeholder="请输入联系人" v-model.trim="item.contacts" maxlength="100"></el-input>
                </el-form-item>

                <el-form-item prop="telephone" label="联系电话">
                  <el-input v-model.trim="item.telephone" placeholder="请输入"></el-input>
                </el-form-item>

                <el-form-item label="备注">
                  <el-input
                    v-model.trim="item.remark"
                    type="textarea"
                    maxlength="500"
                    :rows="4"
                    resize="none"
                    show-word-limit
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 详情展示 -->
      <div v-else>
        <el-tabs tab-position="left" style="height: 400px;" v-model="activeName">
          <el-tab-pane v-for="item in relatedList" :key="item.id" :name="item.code">
            <!-- label -->
            <el-tooltip
              placement="right"
              slot="label"
              :content="item.relatedPartyName"
              :enterable="false"
            >
              <div class="tab-pane-label">
                <p class="label">{{ item.code ==='0' ? '建设单位' : '相关单位' }}</p>
                <p class="name">{{ item.relatedPartyName }}</p>
              </div>
            </el-tooltip>
            <!-- content -->
            <div class="list-item">
              <el-form ref="form" :model="item" :rules="rules" label-width="80px">
                <el-form-item prop="contactAddress" label="联系地址">
                  <el-input
                    :disabled="detailMode"
                    placeholder="请输入联系地址"
                    v-model.trim="item.contactAddress"
                    maxlength="100"
                  ></el-input>
                </el-form-item>

                <el-form-item prop="contacts" label="联系人">
                  <el-input
                    :disabled="detailMode"
                    placeholder="请输入联系人"
                    v-model.trim="item.contacts"
                    maxlength="100"
                  ></el-input>
                </el-form-item>

                <el-form-item prop="telephone" label="联系电话">
                  <el-input :disabled="detailMode" v-model.trim="item.telephone" placeholder="无"></el-input>
                </el-form-item>

                <el-form-item label="备注">
                  <el-input
                    :disabled="detailMode"
                    v-model.trim="item.remark"
                    type="textarea"
                    maxlength="500"
                    :rows="4"
                    resize="none"
                    show-word-limit
                    placeholder="无"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-row :gutter="20">
      <el-form ref="setupFormRef" :model="relatedList[0]" :rules="rules" label-width="150px">
        <el-col :lg="9" :sm="20" :xs="24">
          <el-form-item prop="establishmentDept" label="立项部门">
            <el-input
              :disabled="detailMode"
              placeholder="请输入立项部门"
              v-model.trim="relatedList[0].establishmentDept"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="9" :sm="20" :xs="24">
          <el-form-item prop="establishmentTelephone" label="联系电话">
            <el-input
              :disabled="detailMode"
              :placeholder="detailMode ? '无' : '请输入'"
              v-model.trim="relatedList[0].establishmentTelephone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="18" :sm="20" :xs="24">
          <el-form-item prop="establishmentRemark" label="备注">
            <el-input
              :disabled="detailMode"
              :placeholder="detailMode ? '无' : '请输入'"
              v-model.trim="relatedList[0].establishmentRemark"
              type="textarea"
              maxlength="500"
              :rows="5"
              resize="none"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';

export default {
  props: {
    relatedList: {
      required: true,
      type: Array,
      default() {
        return [];
      }
    },
    // 建设单位列表
    buildOptions: {
      type: Array
    },
    // 是否为详情模式
    detailMode: {
      type: Boolean,
      dafault: false
    }
  },
  data() {
    // 手机号校验
    const validatePhone = (rule, value, callback) => {
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (!value) {
        callback();
      }
      if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return {
      activeName: '0',
      rules: {
        relatedPartyId: [{ required: true, message: '请选择', trigger: 'change' }],
        contactAddress: [{ required: true, message: '请输入联系地址', trigger: 'blur' }],
        contacts: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        telephone: [{ validator: validatePhone, trigger: 'blur' }],
        establishmentDept: [{ required: true, message: '请输入立项部门', trigger: 'blur' }],
        establishmentTelephone: [{ validator: validatePhone, trigger: 'blur' }]
      }
    };
  },
  methods: {
    // 新增相关单位
    onAddRelated() {
      this.relatedList.push({
        code: nanoid(),
        relatedPartyId: '',
        contactAddress: '',
        contacts: '',
        telephone: '',
        remark: ''
      });
    },
    // 删除相关方
    onDelete(code) {
      this.relatedList.splice(
        this.relatedList.findIndex(item => item.code === code),
        1
      );
    },
    // 表单校验
    handleValidate() {
      let validRes = [];
      this.$refs.setupFormRef.validate(valid => {
        validRes.push(valid);
      });
      this.$refs.form.forEach(item => {
        item.validate(valid => {
          validRes.push(valid);
        });
      });

      // 所有子表单校验成功，则该 项目分段 相关方校验成功
      return validRes.every(item => item === true);
    },
    // 清空校验
    resetValid() {
      this.$refs.setupFormRef.resetFields();
      this.$refs.form.forEach(item => {
        item.resetFields();
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__active-bar,
.is-left {
  height: 64px !important;
  width: 4px !important;
}
// is-left
/deep/ .el-tabs__item {
  height: 64px;
}

/deep/ .el-tabs__item.is-active {
  background: #f3f6fe;
  transition: background 0.2s ease-in-out;
}
/deep/ .el-tabs__nav-prev {
  background-color: #eee;
  color: #000;
}
/deep/ .el-tabs__nav-next {
  background-color: #eee;
  color: #000;
}
.list-wrapper {
  margin: 20px 0;
  padding: 40px;
  // padding-bottom: 0;
  // background: #f3f6fe;
  // background: #fff;
  border: 1px solid #dcdfe7;
  border-radius: 3px;

  .list-item {
    max-width: 500px;
    position: relative;
    padding: 20px;
    padding-right: 30px;
    // height: 336px;
    background: #fff;
    //
    margin-bottom: 40px;

    .el-icon-error {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 20px;
      color: #f56c6c;
      cursor: pointer;
    }
  }

  .tab-pane-label {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;

    .label {
      margin: 0;
      height: 16px;
      font-size: 12px;
      line-height: 16px;
      color: #979fa8;
    }

    .name {
      margin: 0;
      height: 19px;
      font-size: 14px;
      line-height: 19px;
      color: #303133;
    }
  }
}
</style>
