<template>
  <el-table height="400" :data="tableData" v-loading="tableLoading" size="medium" stripe>
    <el-table-column align="center" label="序号" width="60" type="index" fixed></el-table-column>
    <el-table-column
      align="center"
      label="文件名称"
      prop="fileOriginName"
      width="500"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column align="center" label="上传人" prop="createUserName" width="200"></el-table-column>
    <el-table-column align="center" label="档案类型" prop="className" width="250" show-overflow-tooltip></el-table-column>
    <el-table-column
      align="center"
      label="上传时间"
      prop="createTime"
      width="250"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column label="操作" align="center" width="250" fixed="right">
      <template slot-scope="{ row }">
        <div class="comm-operation">
          <span class="edit-btn mr-16" @click="previewFile(row)">查看</span>
          <span
            class="edit-btn"
            :class="detailMode ? '' : 'mr-16'"
            @click="downLoadFile(row.fileId, row.fileOriginName)"
          >下载</span>
          <span v-if="!detailMode" class="delete-btn" @click="onDelete(row.fileId)">删除</span>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { previewFile, downLoadFile } from '@/mixins/uploadFileOperation';

export default {
  mixins: [previewFile, downLoadFile],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    // 是否为详情模式
    detailMode: { type: Boolean, dafault: false }
  },
  data() {
    return {
      tableLoading: false
    };
  },
  methods: {
    onDelete(fileId) {
      this.$emit('on-delete', fileId);
    }
  }
};
</script>

<style lang="less" scoped>
</style>
