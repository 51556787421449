<template>
  <el-form ref="form" :model="projectParam" :rules="rules" label-width="150px">
    <el-row>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item label="项目编号">
          <el-input disabled placeholder="保存后自动生成" v-model="projectParam.projectNo"></el-input>
        </el-form-item>
      </el-col>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="projectName" label="项目名称">
          <el-input
            :disabled="detailMode"
            v-model.trim="projectParam.projectName"
            placeholder="请输入项目名称"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="planStartTime" label="计划开始时间">
          <el-date-picker
            :disabled="detailMode"
            class="w-100p"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择开始时间"
            :picker-options="startTimeOption"
            v-model="projectParam.planStartTime"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="planEndTime" label="计划结束时间">
          <el-date-picker
            :disabled="detailMode"
            class="w-100p"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择结束时间"
            :picker-options="endTimeOption"
            v-model="projectParam.planEndTime"
          ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="projectDuration" label="项目工期（月）">
          <el-input
            :disabled="detailMode"
            v-model.trim="projectParam.projectDuration"
            maxlength="3"
            placeholder="请输入项目工期，单位：月"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="projectAddress" label="项目地址">
          <el-input
            :disabled="detailMode"
            maxlength="100"
            placeholder="请输入项目地址"
            v-model.trim="projectParam.projectAddress"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="engineeringCost" label="工程造价（万元）">
          <el-input
            :disabled="detailMode"
            placeholder="请输入工程造价，单位：万元"
            v-model.trim="projectParam.engineeringCost"
            @change="onChangeCost"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item label="造价大写">
          <el-input disabled placeholder="输入工程造价后自动生成" v-model="projectParam.costBig"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="profit" label="预期利润（万元）">
          <el-input
            :disabled="detailMode"
            placeholder="请输入预期利润，单位：万元"
            v-model.trim="projectParam.profit"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="typeId" label="项目类型">
          <el-select
            filterable
            v-if="!detailMode"
            placeholder="请选择项目类型（可搜索）"
            v-model="projectParam.typeId"
            @change="onChangeType"
          >
            <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input v-else disabled v-model="projectParam.typeName"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item prop="stateId" label="项目状态">
          <el-select
            filterable
            v-if="!detailMode"
            placeholder="请选择项目状态（可搜索）"
            v-model="projectParam.stateId"
            @change="onChangeState"
          >
            <el-option v-for="item in stateOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input v-else disabled v-model="projectParam.stateName"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :lg="18" :sm="20" :xs="24">
        <el-form-item prop="buildContent" label="主要建设内容">
          <el-input
            :disabled="detailMode"
            placeholder="请输入主要建设内容"
            v-model.trim="projectParam.buildContent"
            type="textarea"
            :rows="5"
            resize="none"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item label="工程等级" prop="engineeringGrade">
          <el-input
            :disabled="detailMode"
            :placeholder="detailMode ? '无' : '请输入'"
            v-model.trim="projectParam.engineeringGrade"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :lg="9" :sm="20" :xs="24">
        <el-form-item label="项目跟踪人" prop="projectTracker">
          <el-input
            :disabled="detailMode"
            :placeholder="detailMode ? '无' : '请输入'"
            v-model.trim="projectParam.projectTracker"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :lg="18" :sm="20" :xs="24">
        <el-form-item label="备注" prop="remark">
          <el-input
            :disabled="detailMode"
            :placeholder="detailMode ? '无' : '请输入'"
            v-model.trim="projectParam.remark"
            type="textarea"
            :rows="5"
            resize="none"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { numberFun } from '@/utils/capitalizeNumber.js';

export default {
  props: {
    projectParam: {
      required: true,
      type: Object,
      default() {
        return {};
      }
    },
    // 是否为详情模式
    detailMode: {
      type: Boolean,
      dafault: false
    }
  },
  data() {
    // 金额校验
    const validateMoney = (rule, value, callback) => {
      // 正数正则
      const numberReg = /^[0-9]{0,8}?.?\d{0,6}$/;
      if (!value) {
        callback();
      } else {
        if (!numberReg.test(value)) {
          callback('请输入数字，小数点后不超过6位！');
        } else if (value >= 100000000) {
          callback('金额最大不超过8位！');
        } else {
          callback();
        }
      }
    };
    // 数字检验
    const validateNumber = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      if (/^0|[^\d]/g.test(value)) {
        callback(new Error('请输入数字'));
      } else {
        callback();
      }
    };
    return {
      startTimeOption: {
        disabledDate: time => {
          if (this.projectParam.planEndTime) {
            return time.getTime() > new Date(this.projectParam.planEndTime).getTime();
          } else {
            return false;
          }
        }
      },
      endTimeOption: {
        disabledDate: time => {
          if (this.projectParam.planStartTime) {
            return time.getTime() < new Date(this.projectParam.planStartTime).getTime() - 1 * 24 * 60 * 60 * 1000;
          } else {
            return false;
          }
        }
      },
      rules: {
        projectName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        planStartTime: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
        planEndTime: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
        projectDuration: [
          { required: true, message: '请输入项目工期，单位：月', trigger: 'blur' },
          { validator: validateNumber, trigger: 'change' }
        ],
        projectAddress: [{ required: true, message: '请输入请输入项目地址', trigger: 'blur' }],
        engineeringCost: [
          { required: true, message: '请输入工程造价，单位：万元', trigger: 'blur' },
          { validator: validateMoney, trigger: 'blur' }
        ],
        profit: [
          { required: true, message: '请输入预期利润，单位：万元', trigger: 'blur' },
          { validator: validateMoney, trigger: 'blur' }
        ],
        typeId: [{ required: true, message: '请选择项目类型', trigger: 'change' }],
        stateId: [{ required: true, message: '请选择项目状态', trigger: 'change' }],
        buildContent: [{ required: true, message: '请输入主要建设内容', trigger: 'blur' }]
      },
      // TODO: 获取项目类型下拉列表
      typeOptions: [],
      // 项目状态列表
      stateOptions: []
    };
  },
  created() {
    this.typeOptions = this.$attrs['type-list'];
    this.stateOptions = this.$attrs['status-list'];
  },
  methods: {
    // 输入造价金额，计算金额大写
    onChangeCost(value) {
      const numberReg = /^[0-9]{0,8}?.?\d{0,6}$/;
      if (!numberReg.test(value)) {
        this.projectParam.costBig = '';
      } else if (value >= 100000000) {
        this.projectParam.costBig = '';
      } else {
        this.projectParam.costBig = numberFun(this.mul(value, 10000));
      }
    },
    mul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split('.')[1].length;
      } catch (e) {}
      try {
        m += s2.split('.')[1].length;
      } catch (e) {}
      return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
    },

    // 选择类型，赋值 typeName
    onChangeType(id) {
      this.projectParam.typeName = this.typeOptions.find(item => item.id === id).name;
    },
    // 选择状态，赋值 stateName
    onChangeState(id) {
      this.projectParam.stateName = this.stateOptions.find(item => item.id === id).name;
    },
    // 表单校验
    handleValidate() {
      let validateRes;
      this.$refs.form.validate(valid => {
        validateRes = valid;
      });
      return validateRes;
    },
    // 清空校验
    resetValid() {
      this.$refs.form.resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
.w-100p {
  width: 100%;
}
</style>
