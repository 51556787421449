<template>
  <div style="margin-bottom: 50px">
    <el-upload
      v-if="!detailMode"
      :disabled="activeName === '0'"
      :headers="{ Authorization: 'Bearer ' + token, AppCode: 'whole-process' }"
      :action="uploadUrl"
      multiple
      :show-file-list="false"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :on-progress="uploadProgress"
    >
      <el-button class="upload-button" :disabled="activeName === '0'">
        <i class="el-icon-plus"></i>上传附件
      </el-button>
    </el-upload>
    <!-- <el-button icon="el-icon-plus" @click="onUpload" >上传附件</el-button> -->
    <el-tabs tab-position="left" style="height: 400px;margin-top: 24px" v-model="activeName">
      <el-tab-pane
        :disabled="tabPanDisabled"
        v-for="item in fileTypeList"
        :key="item.id"
        :name="item.id"
      >
        <!-- label -->
        <el-tooltip placement="right" slot="label" :content="item.name" :enterable="false">
          <div class="tab-pane-label">{{ item.name }}</div>
        </el-tooltip>
        <!-- content -->
        <FileTable
          v-if="item.id === activeName"
          v-loading="tableLoading"
          :table-data="tableData"
          :detail-mode="detailMode"
          @on-delete="handleDelete"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FileTable from './projectFileTable.vue';

export default {
  components: { FileTable },
  props: {
    fileList: { required: true, type: Array, default: () => [] },
    fileTypeList: { required: true, type: Array, default: () => [] },
    // 是否为详情模式
    detailMode: { type: Boolean, dafault: false }
  },
  data() {
    return {
      uploadUrl: 'api/roses-pub-service/sysFileInfo/uploadResponse',
      activeName: '0',
      tableLoading: false,
      tabPanDisabled: false
    };
  },
  computed: {
    ...mapGetters(['token']),
    tableData() {
      const { fileList, activeName } = this;
      // 全部文件列表
      if (activeName === '0') {
        return fileList;
      }
      let arr = [];
      fileList.forEach(item => {
        if (item.classId === activeName) {
          arr.push(item);
        }
      });
      return arr;
    }
  },
  methods: {
    setActiveName(name) {
      this.activeName = name;
    },
    // 删除文件
    handleDelete(fileId) {
      const index = this.fileList.findIndex(item => item.fileId === fileId);
      this.fileList.splice(index, 1);
    },
    // 上传成功
    uploadSuccess(response) {
      this.tableLoading = false;
      this.tabPanDisabled = false;
      const { activeName, fileTypeList } = this;
      const res = response.data;
      // console.log(res);
      this.fileList.push({
        classId: activeName,
        className: fileTypeList.find(item => item.id === activeName).name,
        fileId: res.fileId,
        fileOriginName: res.fileOriginName,
        filePath: res.filePath,
        fileSuffix: res.fileSuffix,
        createUserName: res.createUserName,
        createTime: res.createTime
      });
      this.$message.success('上传成功！');
    },
    // 上传失败
    uploadError(err) {
      this.tableLoading = false;
      this.tabPanDisabled = false;
      this.$message.error(err.message);
    },
    // 上传中
    uploadProgress() {
      this.tabPanDisabled = true;
      this.tableLoading = true;
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__active-bar,
.is-left {
  height: 64px !important;
  width: 4px !important;
}
// is-left
/deep/ .el-tabs__item {
  height: 64px;
}

/deep/ .el-tabs__item.is-active {
  background: #f3f6fe;
  transition: background 0.2s ease-in-out;
  .tab-pane-label {
    font-size: 16px;
  }
}

/deep/ .el-tabs__nav-prev {
  background-color: #eee;
  color: #000;
}
/deep/ .el-tabs__nav-next {
  background-color: #eee;
  color: #000;
}

.tab-pane-label {
  font-size: 14px;
  max-width: 200px;
  height: 64px;
  line-height: 64px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
